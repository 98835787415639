import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  checkPhoneNoFieldsCount(e) {
    e.preventDefault();

    const phoneNoFields = document.querySelectorAll('.phone-number-action-link');
    const phoneNoFieldsCount = phoneNoFields.length;

    if (phoneNoFieldsCount >= 9) this.addPhoneNoBtn("hide");
    if (this.element.dataset.showAddNewBtn) this.addPhoneNoBtn("show");
  }

  addPhoneNoBtn(action) {
    const addPhoneNoBtn = document.querySelector('#add-new-phone-number-first-manual-entry-phone-number-field');
    action === "hide" ? addPhoneNoBtn.classList.add('vanish') : addPhoneNoBtn.classList.remove('vanish')
  }
}